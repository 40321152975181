import React, { useContext, useEffect, useRef, useState } from 'react'
import "./HomePage.scss";
import VerificationDataContext from '../context/VerificationDataContext';
import { faChevronDown, faChevronUp, faCircleCheck, faEnvelope, faMobileScreenButton, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import {  Autocomplete, FormControl, InputLabel, TextField, Typography } from "@mui/material";
// import TokenPayment from './TokenPayment';
import EmailImg from "../assets/img/icon/email.png";
import MobileImg from "../assets/img/icon/mobile.png";
import VerifiedIcon from "../assets/img/icon/verified-btn.png";
import ActionIcon from "../assets/img/icon/action-btn.png";
import { checkUser, sendOTP } from '../utils/verificationAPI';
import VerifyOTP from './VerifyOTP';
import PaymentSDK from './PaymentSDK';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

const HomePage = () => {

  const {
    verificationObj,
    verificationID,
    ShowNotification,
    setIsTimeOut,
    setTimeLeft,
    verificationLevel,
    setVerificationLevel,
    handleSendOTP,
    isCSTBased,
    isPaymentDone,
    isValidId,
    isExpired,
    name, setName,
    mobileNumber,
    setMobileNumber,
    countryCode,
    setCountryCode,
    otpSent,setOtpSent,
    setOtp,
    setHashedMobile,
    setIsPreLoading,
    leadSource,
    leadId, setLeadId,
    countryCodes,
    selectedCountryCode, setSelectedCountryCode
  } = useContext(VerificationDataContext);

  /*  */
  const navigate = useNavigate();

  //Country code
  const hanldleCountryCodeChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption);
    setShowDropdown(false)
    setTimeout(() => {
      phonenumberRef.current.focus();
    }, 100);
    
  };

  const handleCheckUser = async (type) => {    
    
    if(mobileNumber && name.trim() && selectedCountryCode){

      setIsPreLoading(true);

      let requestData = {
        country_code: selectedCountryCode?.dial_code.slice(1),
        mobile: mobileNumber,
        name: name,
        lead_source: leadSource || "website",
      };
  
      try {
        const checkUserResponse = await checkUser(requestData);  

        setLeadId(checkUserResponse?.data?.lead_id);
        handleSendOTPV1(checkUserResponse?.data?.lead_id);

        // if(checkUserResponse?.data?.is_user){
        //   setIsPreLoading(false);
        //   ShowNotification("info","Your number is already registered with us. You can login through the app")
        // }else{
        //   setLeadId(checkUserResponse?.data?.lead_id);
        //   handleSendOTPV1(checkUserResponse?.data?.lead_id);
        // }  
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);  
        setIsPreLoading(false);
        ShowNotification("error", error.message);  
      } finally {
        // 
      }
    }else{
      ShowNotification("info", "Please enter valid details");
    }
   
  };

  const handleSendOTPV1 = async (userLeadID) => {
    
    if(mobileNumber){
      // setIsPreLoading(true);

      let requestData = {
        country_code: selectedCountryCode?.dial_code.slice(1),
        mobile: mobileNumber,
        lead_id: userLeadID,
      };
  
      try {
        const sendOTPResponse = await sendOTP(requestData);
  
        if (sendOTPResponse?.status === "success") {
          
          setHashedMobile(sendOTPResponse?.data?.mh);
          setOtpSent(true);
          setVerificationLevel("otp"); //change

          setTimeout(() => {
            // navigate("/verifyotp");         
            setIsTimeOut(false);
            setTimeLeft(180);
          }, 900);
  
        } else if (sendOTPResponse?.status === "error") {
  
          ShowNotification("error", "Unable Send OTP");
          
        }
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
  
        ShowNotification("error", error.message);
  
      } finally {
        setIsPreLoading(false);
      }
    }else{
      ShowNotification("info", "Please enter valid phone number");
    }
   
  };

  /*  */

  const [showDropdown, setShowDropdown] = useState(false);
  const selectRef = useRef(null);
  const phonenumberRef = useRef(null);
  const inputGroupRef = useRef(null);

  useEffect(() => {
    if (showDropdown && selectRef.current) {
      selectRef.current.focus();
    }
  }, [showDropdown]);


  useEffect(() => {
    const handleOutsideClick = (event) => {
        const formGroup = document.querySelector('.phone-input');
        const clickedInsideFormGroup = formGroup && formGroup.contains(event.target);

        if (!clickedInsideFormGroup) {
            setShowDropdown(false);
        }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
        window.removeEventListener("click", handleOutsideClick);
    };
}, [showDropdown]);

  const currentYear = new Date().getFullYear();



  return (
    <>
      {verificationID && (
        <div className="testing-expired">
          <h4 className="mb-3">The page has been expired.</h4>
          <h5 className="mb-2" style={{ lineHeight: "1.5" }}>
            Please contact support{" "}
            <a href="mailto:support@deepholistics.com">
              support@deepholistics.com
            </a>
            .
          </h5>
        </div>
      )}
      {!verificationID && (
        <div className="verification-home">
          <h4 className="welcome-text">Welcome to</h4>
          <h4 className="app-name">Deep Holistics</h4>

          <section className="verification-user-input">
            <Form.Group className="mb-3 text-start name-input">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  if (e.target.value?.length <= 50) {
                    setName(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-start phone-input">
              <Form.Label>Phone</Form.Label>

                <InputGroup className="" ref={inputGroupRef}>
                  <InputGroup.Text onClick={() => setShowDropdown(!showDropdown)}>
                    {selectedCountryCode?.dial_code} 
                    {showDropdown ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}

                  </InputGroup.Text>
                  <Form.Control
                    type="tel"
                    ref={phonenumberRef}
                    value={mobileNumber}
                    name="phone"
                    onClick={()=>{
                      setShowDropdown(false)
                      inputGroupRef.current && inputGroupRef.current.focus();

                    }}
                    onChange={(e) => {
                      
                      const input = e.target.value;
                      const sanitizedInput = input.replace(/\D/g, "");
                      if (sanitizedInput.length <= 15) {
                        setMobileNumber(sanitizedInput);
                      }
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleCheckUser();
                      }
                    }}
                  />
                </InputGroup>
              <div className="country-input">
                {showDropdown && (
                  <Select
                    ref={selectRef}
                    value={selectedCountryCode?.dial_code}
                    onChange={hanldleCountryCodeChange}
                    options={countryCodes}
                    className="phonenumber-selector"
                    placeholder=""
                    isSearchable
                    menuPlacement="bottom"
                    menuIsOpen={true} 
                    defaultMenuIsOpen={true}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                        width: "100%",
                        right: 0,
                      }),
                    }}
                  />
                )}
              </div>
            </Form.Group>

            {/* <h4 className="typo-text">Name</h4>
            <TextField
              className="mb-1"
              type="text"
              // label="Name"
              // placeholder="Name"
              size={"small"}
              fullWidth
              variant="outlined"
              style={{
                width: "100%",
              }}
              value={name}
              onChange={(e) => {
                if (e.target.value?.length <= 50) {
                  setName(e.target.value);
                }
              }}
            /> */}

            {/* <h4 className="typo-text">Phone</h4>
            <FormControl
              // fullWidth
              className="country-code-input"
            >
              <Autocomplete
                id="select-label"
                options={countryCodes}
                getOptionLabel={(option) =>
                  `${option.name} (${option.dial_code})`
                }
                value={selectedCountryCode}
                onChange={hanldleCountryCodeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Country Code"
                    variant="outlined"
                    // size="small"
                  />
                )}
                PopperProps={{
                  placement: "bottom-start",
                }}
              />
              <TextField
                // label="Phone Number"
                // placeholder="Phone Number"
                type="tel"
                // size={"small"}
                // fullWidth
                variant="outlined"
                value={mobileNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  const sanitizedInput = input.replace(/\D/g, "");
                  if (sanitizedInput.length <= 15) {
                    setMobileNumber(sanitizedInput);
                  }
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleSendOTPV1();
                  }
                }}
              />
            </FormControl> */}
          </section>

          <section className="verification-footer-section">
            <div className='copyright-text'>&copy; IOT Monks Private Limited {currentYear}</div>
            <Button
              variant="dark"
              className="send-otp-button"
              disabled={!name?.trim() || !mobileNumber?.trim()}
              style={{ cursor: !name?.trim() || !mobileNumber?.trim() ? 'not-allowed' : 'pointer' }}
              onClick={() => {
                handleCheckUser();
                // handleSendOTPV1()
              }}
            >
              Next
            </Button>
          </section>
        </div>
      )}
    </>
  );
}

export default HomePage